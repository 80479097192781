import { R as REPORT_TYPE, b as REPORT_NAME } from "./audit.others.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "reports-menu" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("h1", { staticClass: "header-title px-5 pb-3" }, [_vm._v("Audit Reports")]), _c("a-spin", { attrs: { "spinning": _vm.isLoading } }, [_c("div", { staticClass: "p-5" }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("a-card", { staticClass: "reports-card" }, [_c("h3", { staticClass: "section-title mb-3" }, [_vm._v("Preview Audit Reports")]), _c("a-row", { attrs: { "gutter": 24 } }, _vm._l(_vm.reports, function(report) {
    return _c("a-col", { key: report.name, staticClass: "mb-4", attrs: { "xl": 12, "xs": 24 } }, [_c("a-card", [_c("a-icon", { style: _vm.icon.style, attrs: { "type": report.icon } }), _c("span", [_vm._v(_vm._s(report.title))]), _c("a-button", { staticClass: "btn-outline-primary", attrs: { "disabled": report.disabled }, on: { "click": function($event) {
      return _vm.preview(report.name);
    } } }, [_vm._v("Preview")])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)])], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".reports-menu .header-title[data-v-138f241e]{font-size:20px;color:#272d35;background-color:#fff}.reports-menu .section-title[data-v-138f241e]{font-size:16px;color:#272d35}.reports-menu .btn-outline-primary[data-v-138f241e]{flex-basis:90px;border-color:#0062ff;color:#0062ff}.reports-menu .btn-outline-primary span[data-v-138f241e]{font-size:14px;letter-spacing:.02em}.reports-menu .btn-outline-primary[data-v-138f241e]:hover{background-color:#0062ff;color:#fff}.reports-menu .btn-outline-primary[disabled][data-v-138f241e]{color:#00000040;background-color:#f5f5f5;border-color:#d9d9d9;text-shadow:none;box-shadow:none}.reports-menu[data-v-138f241e] .reports-card>.ant-card-body{padding-bottom:0}.reports-menu[data-v-138f241e] .reports-card>.ant-card-body .ant-card-body{display:flex;align-items:center;flex-wrap:wrap}.reports-menu[data-v-138f241e] .reports-card>.ant-card-body .ant-card-body i{flex-basis:70px}.reports-menu[data-v-138f241e] .reports-card>.ant-card-body .ant-card-body>span{flex:1;padding-right:30px;font-size:16px;color:#272d35}\n")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "ReportsMenu",
  data() {
    return {
      apiUrl,
      REPORT_TYPE,
      REPORT_NAME,
      itemsMenu: [
        {
          key: "reports",
          title: "Reports",
          path: ""
        },
        {
          key: "auditreports",
          title: "Audit Reports",
          path: "/sterilization-audits/reports"
        }
      ],
      asd: "ASDAAD",
      params: {},
      icon: {
        theme: "twoTone",
        style: {
          fontSize: "21px",
          color: "#0062FF"
        }
      },
      reports: [
        {
          name: REPORT_NAME.DUE,
          title: "Audits Due",
          icon: "clock-circle",
          disabled: false
        },
        {
          name: REPORT_NAME.COMPLETED,
          title: "Completed Audits",
          icon: "check-circle",
          disabled: false
        },
        {
          name: REPORT_NAME.SCHEDULE,
          title: "Audits From Schedule",
          icon: "calendar",
          disabled: false
        },
        {
          name: REPORT_NAME.NEVER,
          title: "Product Never Been Audited",
          icon: "close-circle",
          disabled: false
        }
      ],
      isLoading: false
    };
  },
  methods: {
    preview(type) {
      this.getReportUri(type);
    },
    async getReportUri(reportName) {
      const reportApiUrl = apiUrl + `/identities/reports`;
      const payload = {
        report: reportName,
        reportType: "steri-audit",
        reportParams: {}
      };
      try {
        this.isLoading = true;
        const res = await this.axios.post(reportApiUrl, payload);
        if (res && res.data && res.data.reportViewerUri) {
          window.open(res.data.reportViewerUri, "_blank");
        }
      } catch (error) {
        this.$notification["error"]({
          message: "Can not complete your request. Please try again later."
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "138f241e", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
